import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { getFirestore } from '@firebase/firestore';
import { getStorage } from '@firebase/storage';

const firebaseConfig = {
  apiKey: process.env['REACT_APP_FIREBASE_API_KEY'],
  authDomain: process.env['REACT_APP_FIREBASE_AUTH_DOMAIN'],
  projectId: process.env['REACT_APP_FIREBASE_PROJECT_ID'],
  storageBucket: process.env['REACT_APP_STORAGE_BUCKET'],
  messagingSenderId: process.env['REACT_APP_MESSAGING_SENDER_ID'],
  appId: process.env['REACT_APP_APP_ID'],
  measurementId: process.env['REACT_APP_MEASUREMENT_ID'],
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
logEvent(analytics, 'notification_received');
export const fireStore = getFirestore(app);
export const storage = getStorage(app);
